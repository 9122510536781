<template>
    <supportLayout>
        <div class="p-faq">

            <v-container>
                <section class="about">
                    <h3 class="title">About</h3>
                    <div class="description">
                        Welcome to psychurch where we believe psychedelics are the guiding pathway to divine.
                        You have found our online platform for psychedelic music, community and events. We are aware of the many bugs and are working on fixing them while developing new features.
                        <br>
                        Like what you see? Sign up for a premium plan to support further development or donate via patreaon.
                        <br>
                        For updates follow us on facebook <a :href="FACEBOOK" target="_blank">{{ FACEBOOK }}</a>
                        or
                            <a :href="PATREON" target="_blank">{{ PATREON }}</a>
                        </p>
                    </div>
                </section>
            </v-container>


            <!-- ADMIN CONTENT -->
            <template v-if="userRole === 'ADMIN'">
                <section class="faq admin-content">
                    <div class="container">
                        <div class="wrap_create_faq">
                            <div class="create_faq">
                                <h3 class="title">Add to FAQ</h3>

                                <v-progress-circular v-if="createLoader"
                                                     :size="100"
                                                     :width="3"
                                                     color="#20C4F5"
                                                     indeterminate
                                ></v-progress-circular>

                                <input type="text" autofocus
                                       :class="[{invalid: $v.question.$invalid && submitted}, 'input_style']"
                                       v-model="question"
                                       placeholder="Add a question"/>

                                <textarea-autosize :class="{'invalid': $v.answer.$invalid && submitted}"
                                                   placeholder="Add an answer"
                                                   v-model="answer"
                                                   :min-height="110"
                                                   :max-height="510"/>

                                <button type="button" @click="createFaq()" :disabled="$v.question.$invalid || $v.answer.$invalid">Post</button>
                            </div>
                            <div class="discord" v-if="!mobile">
                                <h4 class="discord_head">More questions? Lets chat!</h4>
                                <iframe src="https://discord.com/widget?id=1074682103767236639&theme=dark" width="350" height="350" allowtransparency="true"
                                        frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                            </div>
                        </div>

                        <h3 class="title" v-if="FAQS.length">FAQ</h3>

                        <div class="wrap_questions">
                            <draggable :list="FAQS" class="questions" handle=".handle" v-bind="dragOptions" @change="update(FAQS, $event)">
                                <div class="item_question" v-for="faq of FAQS" :key="faq.id + dynamicFaqKey" :class="{active: faq.show === true}">

                                    <div class="question">
                                        <div class="handle"></div>
                                        <div class="visible" :class="{'show': faq.show === true}" @click="openQuestion(faq)"></div>
                                        <input type="text" v-model="faq.question" :disabled="userRole !== 'ADMIN'"/>

                                        <span class="saved" v-if="itemSaveFaq.status && itemSaveFaq.id === faq.id">Saved!</span>

                                        <button v-if="!itemSaveFaq.status || itemSaveFaq.id !== faq.id && userRole === 'ADMIN'" class="check"
                                                @click="saveFaq(faq)">
                                        </button>
                                        <button v-if="!itemSaveFaq.status || itemSaveFaq.id !== faq.id && userRole === 'ADMIN'" class="faq_delete"
                                                @click="deleteFaq(faq.id)">
                                            <span class="material-icons">delete</span>
                                        </button>

                                    </div>

                                    <textarea-autosize
                                            v-show="faq.show === true"
                                            class="answer"
                                            :disabled="userRole !== 'ADMIN'"
                                            v-model="faq.answer"
                                            ref="contentTextArea"
                                            :min-height="30"/>
                                </div>
                            </draggable>
                        </div>

                    </div>


                    <v-dialog
                            v-if="deleteFaqDialog"
                            v-model="deleteFaqDialog"
                            max-width="490">
                        <div class="delete_head_promt">
                            You are about to remove a question <br>Confirm to proceed
                        </div>
                        <div class="action_delete_faq">
                            <button class="cancel" @click="deleteFaqDialog = false" v-ripple>Cancel</button>
                            <button class="delete_faq" @click="confirmDeleteFaq(deleteItemFaq)" v-ripple>Delete</button>
                        </div>
                    </v-dialog>

                </section>

            </template>

            <!-- USER CONTENT -->
            <template v-if="userRole !== 'ADMIN'">
                <section class="faq user_content">
                    <div class="container">

                        <div class="wrap_questions">
                            <div class="questions">
                                <div class="title_">Frequently Asked Questions</div>

                                <template v-if="loader">
                                    <v-skeleton-loader
                                            type="image"
                                            class="item_track"
                                            v-for="index in 10"
                                            :key="index"
                                    ></v-skeleton-loader>
                                </template>

                                <template v-if="!loader">
                                    <div class="item_question" v-for="faq of FAQS" :key="faq.id + dynamicFaqKey">
                                        <div class="question" @click="openQuestion(faq)">
                                            <div class="visible" :class="{'show': faq.show === true}"></div>
                                            {{ faq.question }}
                                        </div>
                                        <transition name="fade">
                                            <div class="answer" v-if="faq.show === true" :class="{'show': faq.show === true}">{{ faq.answer }}</div>
                                        </transition>
                                    </div>
                                </template>
                            </div>

                            <div class="discord" v-if="!mobile">
                                <h4>More questions? Lets chat!</h4>
                                <iframe src="https://discord.com/widget?id=1074682103767236639&theme=dark" width="350" height="350" allowtransparency="true"
                                        frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                            </div>
                        </div>

                    </div>
                </section>
            </template>


        </div>
    </supportLayout>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {required, minLength} from 'vuelidate/lib/validators';
import supportLayout from "@/layouts/supportLayout.vue";

import draggable from 'vuedraggable';
import isMobile from "@/mixins/isMobile";

export default {
    name: 'faq',
    components: {
        supportLayout,
        draggable
    },
    mixins: [isMobile],
    data() {
        return {
            createLoader: false,
            loader: true,

            // create faq
            question: '',
            answer: '',
            status: 'active',

            itemFaqOpen: Number,
            dragging: false,

            submitted: false,
            itemSaveFaq: {
                id: null,
                status: false,
            },
            userRole: '',
            deleteFaqDialog: false,
            deleteItemFaq: Object,
            dynamicFaqKey: 1,
        };
    },
    validations: {
        question: {
            required,
            minLength: minLength(2)
        },
        answer: {
            required,
            minLength: minLength(2)
        }
    },

    computed: {
        ...mapGetters(['FAQS', 'AUTH_DATA', 'FACEBOOK', 'PATREON']),

        dragOptions() {
            return {
                animation: 0,
                disabled: false,
            };
        }
    },

    mounted() {
        this.initFaqs();
        if (this.AUTH_DATA.userRoles) {
            if (this.AUTH_DATA.userRoles.find(user => user === 'ROLE_ADMIN')) {
                this.userRole = 'ADMIN';
            }
        }
    },

    methods: {
        ...mapActions(['GET_FAQS', 'ADD_FAQ', 'UPDATE_FAQ', 'DELETE_FAQ']),
        openQuestion(itemFaq) {
            itemFaq.show = !itemFaq.show;
            this.dynamicFaqKey += 1;
        },

        update: function (faqList, $event) {
            const newPosition = $event.moved.newIndex;
            const updateFaq = faqList[newPosition];

            const update = {
                id: updateFaq.id,
                question: updateFaq.question,
                answer: updateFaq.answer,
                position: newPosition
            };
            this.loader = true;
            this.UPDATE_FAQ(update).then(() => {
                this.loader = false;
            });
        },

        initFaqs() {
            this.GET_FAQS().then(() => {
                this.FAQS.sort((a, b) => a.position - b.position);
                this.loader = false;
            });
        },

        createFaq() {
            this.createLoader = true;
            this.submitted = true;

            if (this.userRole !== 'ADMIN') {
                this.status = 'inactive';
            }
            let createFaq = {
                question: String(this.question),
                answer: String(this.answer),
                status: String(this.status),
                position: Number(this.FAQS.length + 1),
            };
            this.ADD_FAQ(createFaq)
                .then(() => {
                    this.submitted = false;
                    this.question = '';
                    this.answer = '';
                    this.initFaqs();
                })
                .catch((err) => {
                    console.log(`createFaq ${err}`);
                })
                .finally(() => {
                    this.createLoader = false;
                })
        },

        saveFaq(faq) {
            this.loader = true;
            this.UPDATE_FAQ(faq).then(() => {
                this.loader = false;
                this.itemSaveFaq.status = true;
                this.itemSaveFaq.id = faq.id;
                setTimeout(() => this.itemSaveFaq.status = false, 1800);
            });
        },

        deleteFaq(id) {
            this.deleteFaqDialog = true;
            this.deleteItemFaq = id;
        },
        confirmDeleteFaq() {
            if (this.deleteItemFaq) {
                this.DELETE_FAQ(this.deleteItemFaq).then(() => {
                    this.loader = false;
                    this.initFaqs();
                })
                this.deleteFaqDialog = false;
            }
        }
    }
};
</script>

<style lang="scss">
.v-dialog {
    background: #282C47;
    padding: 32px 27px;

    .delete_head_promt {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        color: #fff;
        text-align: center;
        margin-bottom: 32px;
    }

    .action_delete_faq {
        display: flex;
        justify-content: space-between;

        > button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 210px;
            height: 48px;
            border-radius: 120px;
            font-size: 18px;
            font-weight: 500;
            color: #fff;

            &.cancel {
                border: 1px solid #5F6986;
            }

            &.delete_faq {
                background: #D21B1B;
            }
        }
    }
}
</style>

